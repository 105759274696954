const certifications = [
  {
    organization: 'Google Cloud',
    year: '2025',
    certificate: {
      link: 'https://www.coursera.org/account/accomplishments/verify/IJJO0EON1YZ5',
    },
    course: {
      link: 'https://www.coursera.org/learn/deploying-secure-kubernetes-containers-in-production',
      title: 'Architecting with Google Kubernetes Engine: Production',
    },
  },
  {
    organization: 'Amazon Web Services',
    year: '2023',
    certificate: {
      link: 'https://www.credly.com/badges/60d34fa1-bac0-46cf-8854-6474faebb800/public_url',
    },
    course: {
      link: 'https://aws.amazon.com/certification/certified-cloud-practitioner/',
      title: 'AWS Certified Cloud Practitioner',
    },
  },
  {
    organization: 'Google Cloud',
    year: '2023',
    certificate: {
      link: 'https://www.coursera.org/account/accomplishments/verify/WADCKAM3KMZH',
    },
    course: {
      link: 'https://www.coursera.org/learn/deploying-workloads-google-kubernetes-engine-gke',
      title: 'Architecting with Google Kubernetes Engine: Workloads',
    },
  },
  {
    organization: 'Google Cloud',
    year: '2023',
    certificate: {
      link: 'https://www.coursera.org/account/accomplishments/verify/HWJ32252FAQ7',
    },
    course: {
      link: 'https://www.coursera.org/learn/foundations-google-kubernetes-engine-gke',
      title: 'Architecting with Google Kubernetes Engine: Foundations',
    },
  },
  {
    organization: 'Udemy',
    year: '2023',
    certificate: {
      link: 'https://www.udemy.com/certificate/UC-6bf486ed-218f-4ac8-8cf0-6f42b7ed2832',
    },
    course: {
      link: 'https://www.udemy.com/course/postgresql-replication-high-availability-ha-and-scalability',
      title: 'PostgreSQL Replication, High Availability HA and Scalability',
    },
  },
  {
    organization: 'By Eric Freeman',
    year: '2023',
    certificate: {
      link: 'https://drive.google.com/file/d/1tTzQGAJTygnRO0rHGbIYXVe57kzCqyiM/view?usp=sharing',
    },
    course: {
      link: 'https://www.linkedin.com/learning/advanced-design-patterns-design-principles',
      title: 'Advanced Design Patterns: Design Principles',
    },
  },
  {
    organization: 'LinkedIn Learning',
    year: '2022',
    certificate: {
      link: 'https://www.linkedin.com/learning/certificates/6efb0cca26e6fe833f83e0dbf5e5cb52b93529d82aa2ff979fd7c3f3a9447e0a',
    },
    course: {
      link: 'https://www.linkedin.com/learning/aws-essential-training-for-developers-2019',
      title: 'AWS Training for Developers',
    },
  },
  {
    organization: 'Udemy',
    year: '2021',
    certificate: {
      link: 'https://www.udemy.com/certificate/UC-ac8686bf-4aa1-4b65-89ac-b42e4b710cab',
    },
    course: {
      link: 'https://www.udemy.com/course/complete-react-developer-zero-to-mastery',
      title: 'React Developer(w/ Redux, Hooks, GraphQL)',
    },
  },
  {
    organization: 'Udemy',
    year: '2021',
    certificate: {
      link: 'https://www.udemy.com/certificate/UC-1a5a44ea-6f7f-49e6-8b07-134a6102e87b',
    },
    course: {
      link: 'https://www.udemy.com/course/advanced-javascript-concepts',
      title: 'JavaScript - The Advanced Concepts',
    },
  },
  {
    organization: 'University of Michigan',
    year: '2020',
    certificate: {
      link: 'https://www.coursera.org/account/accomplishments/specialization/WWYRUS67RZ29',
    },
    course: {
      link: 'https://www.coursera.org/specializations/web-applications',
      title: 'Web Applications Specialization',
    },
  },
  {
    organization: 'University of California San Diego',
    year: '2020',
    certificate: {
      link: 'https://www.coursera.org/account/accomplishments/verify/DPXRNCHY3K8R',
    },
    course: {
      link: 'https://www.coursera.org/learn/algorithmic-toolbox',
      title: 'Algorithmic Toolbox',
    },
  },
  {
    organization: 'University of Illinois',
    year: '2020',
    certificate: {
      link: 'https://www.coursera.org/account/accomplishments/verify/TARQE2WMSR8Y',
    },
    course: {
      link: 'https://www.coursera.org/learn/cluster-analysis',
      title: 'Cluster Analysis in Data Mining',
    },
  },
  {
    organization: 'Wharton School - UPenn',
    year: '2020',
    certificate: {
      link: 'https://www.coursera.org/account/accomplishments/verify/4VB2LBVK4RWA',
    },
    course: {
      link: 'https://www.coursera.org/learn/wharton-social-human-capital',
      title: 'Managing Social and Human Capital',
    },
  },
  {
    organization: 'Stanford University',
    year: '2020',
    certificate: {
      link: 'https://www.coursera.org/account/accomplishments/verify/AGUM2GYWLKPU',
    },
    course: {
      link: 'https://www.coursera.org/learn/algorithms-divide-conquer',
      title:
        'Divide and Conquer, Sorting and Searching, and Randomized Algorithms',
    },
  },
  {
    organization: 'Nirma University',
    year: '2019',
    certificate: {
      link: 'https://drive.google.com/file/d/1sUcKbxy0YCyTHG8uuxP5KcNJS7hwwbQe/view?usp=sharing',
    },
    course: {
      link: '',
      title: 'Python for Data Science',
    },
  },
  {
    organization: 'IIT Bombay',
    year: '2018',
    isWorkshop: true,
    certificate: {
      link: 'https://drive.google.com/file/d/176VP8yH_ddcMDsuez0v41qR1cv1LXF8D/view?usp=share_link',
    },
    course: {
      link: '',
      title: 'Cloud Computing',
    },
  },
  // {
  //   title: 'Managing Social and Human Capital',
  //   organization: ' The Wharton School | UPenn',
  //   link: 'https://www.coursera.org/account/accomplishments/verify/4VB2LBVK4RWA',
  //   year: '2020',
  // },
  // {
  //   title: 'Advanced Design Patterns: Design Principles',
  //   organization: 'LinkedIn',
  //   link: 'https://drive.google.com/file/d/1tTzQGAJTygnRO0rHGbIYXVe57kzCqyiM/view?usp=sharing',
  //   year: '2023',
  // },
  // {
  //   title: 'Advanced Design Patterns: Design Principles',
  //   organization: 'LinkedIn',
  //   link: 'https://drive.google.com/file/d/1tTzQGAJTygnRO0rHGbIYXVe57kzCqyiM/view?usp=sharing',
  //   year: '2023',
  // },
  // {
  //   title: 'Advanced Design Patterns: Design Principles',
  //   organization: 'LinkedIn',
  //   link: 'https://drive.google.com/file/d/1tTzQGAJTygnRO0rHGbIYXVe57kzCqyiM/view?usp=sharing',
  //   year: '2023',
  // },
  // {
  //   title: 'Advanced Design Patterns: Design Principles',
  //   organization: 'LinkedIn',
  //   link: 'https://drive.google.com/file/d/1tTzQGAJTygnRO0rHGbIYXVe57kzCqyiM/view?usp=sharing',
  //   year: '2023',
  // },
  // {
  //   title: 'Managing Social and Human Capital',
  //   organization: ' The Wharton School | UPenn',
  //   link: 'https://www.coursera.org/account/accomplishments/verify/4VB2LBVK4RWA',
  //   year: '2020',
  // },
  // {
  //   title:
  //     'Divide and Conquer, Sorting and Searching, and Randomized Algorithms',
  //   organization: 'Stanford University',
  //   link: 'https://www.coursera.org/account/accomplishments/verify/AGUM2GYWLKPU',
  //   year: '2020',
  // },
];

export default certifications;
