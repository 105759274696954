import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import dayjs from 'dayjs';
import Markdown from 'markdown-to-jsx';
import Main from '../layouts/Main';
import experienceData from '../data/experience';

const renderSideBarNavigator = (projects, setActiveProject, activeProject) => (
  <div className="sidebar-navigator-cell-container">
    {projects.map((project) => (
      <div
        className={
          activeProject.id === project.id
            ? 'sidebar-cell active-cell'
            : 'sidebar-cell'
        }
        onClick={() => setActiveProject(project)}
      >
        {project.title}
      </div>
    ))}
  </div>
);

const renderContentContainer = (activeProject) => (
  <div className="projects">
    <ul className="projectdescription">
      {activeProject.description.map((bullet) => (
        <li>{bullet}</li>
      ))}
    </ul>
  </div>
);

const Experience = () => (
  <Main title="Experience">
    <article className="post" id="experience">
      <header>
        <div className="title">
          <h2>
            <Link to="/experience">Experience</Link>
          </h2>
        </div>
      </header>
      <div className="experience">
        <div className="link-to" id="experience" />

        {/* <p>
          Presenting a sneak peek into my contributions towards my previous
          organization during 2021 and 2022
        </p>
        <div className="imageContainer">
          <img
            src={`${process.env.PUBLIC_URL}/images/projects/gitlab-graph.jpeg`}
            alt="Gitlab Graph"
            height="300"
          />
        </div>
        <br /> */}
        {/* <br /> */}

        {/* <p>Below is the detailed description of my contribution...</p> */}
        {experienceData.map(
          ({
            name,
            position,

            url,
            startDate,
            endDate,
            summary,
            highlights,
            projects,
          }) => {
            const [activeProject, setActiveProject] = useState(projects[0]);

            return (
              <article className="jobs-container">
                <header>
                  <h4 className="heading-4">
                    <a href={url} target="_blank" rel="noreferrer">
                      {position} @ {name}
                    </a>
                  </h4>
                  <p className="daterange">
                    {' '}
                    {dayjs(startDate).format('MMMM YYYY')} -{' '}
                    {endDate !== 'Present' && endDate
                      ? dayjs(endDate).format('MMMM YYYY')
                      : 'Present'}
                  </p>
                </header>
                {summary ? (
                  <Markdown
                    options={{
                      overrides: {
                        p: {
                          props: {
                            className: 'summary',
                          },
                        },
                      },
                    }}
                  >
                    {summary}
                  </Markdown>
                ) : null}
                {highlights ? (
                  <ul className="points">
                    {highlights.map((highlight) => (
                      <li key={highlight}>{highlight}</li>
                    ))}
                  </ul>
                ) : null}
                {projects.length !== 0 ? (
                  <>
                    <h5 className="heading-4">Key Projects</h5>
                    <div className="projects-container">
                      <div className="sidebar-navigator">
                        {renderSideBarNavigator(
                          projects,
                          setActiveProject,
                          activeProject
                        )}
                      </div>
                      <div className="content-container">
                        {renderContentContainer(activeProject)}
                      </div>
                      {/* <div className="project-content"></div> */}
                    </div>
                  </>
                ) : null}
              </article>
            );
          }
        )}
      </div>
    </article>
  </Main>
);

export default Experience;
