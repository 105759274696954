import { faGitlab } from '@fortawesome/free-brands-svg-icons/faGitlab';
import { faGithub } from '@fortawesome/free-brands-svg-icons/faGithub';

const data = [
  {
    title: 'SightSense AI',
    link: 'https://gitlab.com/ish.mak/sightsense-ai',
    image: '/images/projects/sightsenseai.png',
    desc: 'A cloud based project that utilizes AWS Rekognition, EC2, Lambda, SNS, SQS, S3, and DynamoDB services. This project involves label detection, inappropriate image filtering, and the creation of a public gallery.',
    toolstech: 'Javascript, Node.js, Python, React.js, AWS, IaC',
    icon: faGitlab,
    codeOn: 'Gitlab',
  },
  {
    title: 'Kubernetes Docker Microservice Architecture',
    link: 'https://gitlab.com/ish.mak/kubernetes-docker-microservice-pipeline',
    image: '/images/projects/kube-architecture.png',
    desc: 'Implemented end-to-end CI/CD pipeline in GCP utilizing Cloud Build, containerizing the repository using Docker, pushing images to the Artifact registry, and deploying pods. GKE Cluster created using Terraform.',
    toolstech:
      'NodeJS, GCP, Google Kubernetes Engine, Docker, Cloud Build, Artifact Repository, Source Repository',
    icon: faGitlab,
    codeOn: 'Gitlab',
  },
  {
    title: 'Paw Pals',
    link: 'https://gitlab.com/ish.mak/paw-pals',
    image: '/images/projects/pawpals.png',
    desc: 'A Full Stack end-to-end project integrating ReactJS for frontend development, Spring Boot for backend development, and JUnit for testing. Conducted code smell analysis, resolution, and worked on CI/CD, deployment, code quality, refactoring, and while adhering to SOLID principles. Detailed information available in the README.md file.',
    toolstech:
      'Java, Spring Boot, MySql, ReactJS, Javascript, Typescript, JUnit, MaterialUI, JaCoCo, VM, CI/CD',
    icon: faGitlab,
    codeOn: 'Gitlab',
  },
  {
    title: 'DynamiteDB',
    link: 'https://gitlab.com/ish.mak/dynamite-db',
    image: '/images/projects/dynamite.png',
    desc: 'Developed a lightweight DBMS using Java. Implemented Queries DDL and DML. Included features like NULL and AUTO_INCREMENT constraints, along with a User Authentication Module with two-factor authentication. Ensured code modularity for improved maintenance and future scalability.',
    toolstech: 'Java',
    icon: faGitlab,
    codeOn: 'Gitlab',
  },
  {
    title: 'Backend ProdHub',
    link: 'https://github.com/intrepid-ishan/backend-prodhub',
    image: '/images/projects/gadgets.png',
    desc: 'Created a backend system for diverse product sales, featuring user, product, order, and category models. Implemented JWT based authentication for restricted access and included admin-specific protected routes. Project initiated for advancing backend development skills.',
    toolstech: 'NodeJS, Javascript, ExpressJS, MongoDB',
    icon: faGithub,
    codeOn: 'Github',
  },
  {
    title: 'DataFly',
    link: 'https://gitlab.com/ish.mak/datafly',
    image: '/images/projects/datafly.png',
    desc: "Implemented a Java project showcasing an ETL Pipeline architecture. Utilized NewsAPI to extract data, locally saved and processed it, and finally stored it in MongoDB. Developed three engines for extraction, data processing, and transformation. The project's README includes pseudocode and flowcharts for clear workflow understanding.",
    toolstech: 'Java, NewsAPI, MongoDB',
    icon: faGitlab,
    codeOn: 'Gitlab',
  },
  {
    title: 'Clothing Store',
    link: 'https://github.com/intrepid-ishan/360clothing',
    image: '/images/projects/clothing.jpeg',
    desc: 'Developed a clothing store application to gain expertise in Firebase, ReactJS, Redux, and Webpack, while honing skills in component reusability, modularity, and code quality. Implemented crucial functionalities including CRUD operations for shopping cart management, as well as authentication using Google OAuth.',
    toolstech: 'Firebase, Javascript, ReactJS, Redux, SCSS, Webpack',
    icon: faGithub,
    codeOn: 'Github',
  },
  {
    title: 'Mix or Match Game',
    link: 'https://github.com/intrepid-ishan/Mix-Or-Match-Game',
    image: '/images/projects/mixmatchv2.png',
    desc: 'Developed a game using OOP code structure, incorporating the Fisher-Yates shuffle algorithm for card shuffling. Implemented features like time limit, card flipping, card matching, winner declaration',
    toolstech: 'HTML, CSS, VanillaJS, AudioAPI',
    icon: faGithub,
    codeOn: 'Github',
  },
];

export default data;
